import {EchoResponse} from "./model/DataModel";
import {List} from "immutable";

export class Area26CommonClient {
  private readonly url: string;

  constructor(url: string) {
    this.url = url;
  }

  public async getPing(message: string): Promise<List<EchoResponse>> {
    const response = (await (await fetch(this.url, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({query:`query getEcho {getEcho(inputValue: {message: "${message}"}) {message}}`})
    })).json() as any).data.getEcho;

    return List(response);
  }
}