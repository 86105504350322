import React, {useState} from 'react';
import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import {Button} from "react-bootstrap";
import {EchoResponse} from "../client/common/model/DataModel";
import {List} from "immutable";
import {Area26CommonClientFactory} from "../client/common/Area26CommonClientFactory";

interface AppState {
  echoResponses: List<EchoResponse>
  input: string;
}

function App() {
  let [state, setState] = useState<AppState>({echoResponses: List(), input: ''});
  // eslint-disable-next-line no-restricted-globals
  let client = new Area26CommonClientFactory(location.hostname).createClient();

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Hello Minchul! Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <Form.Label htmlFor="messageInput">Message</Form.Label>
        <Form.Control id="messageInput" onChange={(event) => {
          setState(prevState => {
            prevState.input = event.target.value

            return prevState;
          });
        }}/>
        <Form.Text id="messageInputHelp">{JSON.stringify(state.echoResponses)}</Form.Text>
        <Button className='mt-2' variant="primary" onClick={async () => {
          let responses = await client.getPing(state.input);
          setState((prevState) => ({
            echoResponses: responses,
            input: prevState.input
          }))
        }}>Send message</Button>
      </header>
    </div>
  );
}

export default App;
