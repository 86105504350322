import {BaseWebsiteUrl, CommonLambdaUrl} from "../../config/WebsiteConfig";
import {Area26CommonClient} from "./Area26CommonClient";
import {EnvironmentReader, Stage} from "@26area/area26-front-common-typescript";

export class Area26CommonClientFactory {
  private readonly stage: Stage;

  constructor(hostName: string) {
    this.stage = new EnvironmentReader(BaseWebsiteUrl).getStage(hostName);
  }

  public createClient(): Area26CommonClient {
    return new Area26CommonClient(CommonLambdaUrl.get(this.stage)!);
  }
}